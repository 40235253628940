@import '../../../../shared/assets/style/theme.scss';

.gtp-modal-purchase-small {
  margin-top: 40px;
  @media screen and (min-height: 690px) {
    margin-top: 5px;
  }
}

.gtp-modal-purchase {
  width: 45vw;
  min-width: 800px;

  &.modal-tablet {
    min-width: 90%;
  }

  .ModalBackground:before {
    content: none;
  }

  .modal-content {
    background-color: $common-0;
    padding: 0.1rem 2rem 0.1rem 2rem;
    border-radius: 2rem;
  }

  .gradientText {
    @include linearGradientText($common-0, $common-1 20%, $common-32 100%);
    text-shadow: 0px 1px 2px $common-26;
  }

  .form-container {
    .form-title {
      @extend .gradientText;
      line-height: 1;
      font-size: 4vmin;
      font-weight: 600;
      margin: 0 0 3vmin 0;

      position: relative;
      display: flex;
      justify-content: center;
    }

    .form-confirm-button {
      width: 40%;
      height: 100%;
    }

    .form-confirm-button:focus {
      outline: 0 !important;
    }

    .form-input-label.focused {
      top: -10px;
    }

  }

  .ccvForm {
    .form-input-group {
      display: inline-block;
    }
  }

  .saveCard {
    margin-bottom: 1rem;

    .form-input-group {
      display: inline-block;

      .form-check {
        display: none;
      }
    }
  }

  .billingProfileSelect {
    margin-bottom: 2rem;
  }

  .billingProfileSelect,
  .cardType,
  .expiryMonth,
  .expiryYear {
    .form-input-group {
      display: inline-block;
      width: 100%;
    }

    .downIcon {
      margin-left: -2.5em;
      margin-bottom: 0.4em;
    }

    &.isMobile {
      .form-input-text {
        height: 4rem;
      }
    }
  }

  .totalContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 3vmin;
    font-weight: 500;

    .price {
      display: flex;
      justify-content: space-between;
      width: 38%;
      line-height: 1;
      font-size: 3.5vmin;
      font-weight: 600;
    }
  }

  .billingProfileLabel {
    color: $common-6;
    font-size: 2.5vmin;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0;

    .profileControls {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      span:hover {
        cursor: pointer;
      }

      > span {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.2rem;
        width: 6rem;
        text-align: center;
        padding: 0 0.625rem;
        font-size: 2vmin;
        font-weight: 400;
        border: 2px solid $primary-26;
        border-radius: 0.375rem;
        color: $common-1;
      }
    }
  }
}

:root[data-pwa-mode='mobile-standalone'] .gtp-modal-purchase,
:root[data-pwa-mode='mobile-browser'] .gtp-modal-purchase {
  .cardNumber {
    .form-input-text {
      height: 4rem;
    }
    .form-input-label {
      top: 25px;
    }
    .form-input-label.focused {
      top: 8px;
    }
  }

  .ccvForm {
    .form-input-label {
      top: 22px;
    }

    .form-input-label.focused {
      top: -10px;
    }
  }

  .cardNickName {
    .form-input-text {
      height: 4rem;
    }
    .form-input-label {
      top: 25px;
    }
    .form-input-label.focused {
      top: 8px;
    }
  }
}

